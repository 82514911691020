import React from 'react';
import Header from '../../components/Header/Header';
import style from './Home.module.scss';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import noAvatar from '../../assets/images/no_avatar.jpg'

import searchIcon from '../../assets/icons/search.svg'
import arrowIcon from '../../assets/icons/arrow-right.svg'
import arrowRightIcon from '../../assets/icons/arrow-right-light.svg'

import Chiropractic from '../../assets/mock/Chiropractic.png'
import Kinesiology from '../../assets/mock/Kinesiology.png'
import Physiotherapy from '../../assets/mock/Physiotherapy.png'
import Gynecology from '../../assets/mock/Gynecology.png'
import Psychology from '../../assets/mock/Psychology.png'
import Nutrition from '../../assets/mock/Nutrition.png'

// import Create from '../../assets/images/create.png'
// import addContent from '../../assets/images/add-content.png'
// import launch from '../../assets/images/launch.png'

// import therapist1 from '../../assets/mock/therapist.png'
// import therapist2 from '../../assets/mock/thrapist2.png'
// import therapist3 from '../../assets/mock/thrapist3.png'
// import therapist4 from '../../assets/mock/thrapist4.png'
// import therapist5 from '../../assets/mock/thrapist5.png'
// import therapist6 from '../../assets/mock/thrapist6.png'
// import therapist7 from '../../assets/mock/thrapist7.png'
// import therapist8 from '../../assets/mock/thrapist8.png'

import featureIcon1 from '../../assets/images/feature/Heading.png'
import featureIcon2 from '../../assets/images/feature/Heading(1).png'
import featureIcon3 from '../../assets/images/feature/Heading(2).png'
import featureIcon4 from '../../assets/images/feature/Heading(3).png'
import featureIcon5 from '../../assets/images/feature/Heading(4).png'
import featureIcon6 from '../../assets/images/feature/Heading(5).png'

import Rating from '../../components/Rating/Rating';
import Footer from '../../components/Footer/Footer';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import { BrowserView, isMobileOnly, MobileView } from 'react-device-detect';
import ArrowRightIcon from '../../components/icons/ArrowRightIcon';
import { axiosInstance } from '../../axiosConfig';
import { withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';

const Home = props => {

  React.useEffect(() => {
    document.title = "BankWell";
    getTherapists()
  }, [])

  const [search, setSearch] = React.useState('');
  const [therapists, setTherapists] = React.useState([]);

  const sliderSettings = {
    rtl: false,
    dots: true,
    arrows: false,
    infinite: false,
    // autoplay: true,
    //variableWidth: true,
    // centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const getTherapists = () => {
    axiosInstance.get(`/users?type=consultant&size=8`).then(response => {
      setTherapists(response.data.data)
    }).catch(err => { })
  }

  return (
    <>
      <Header />
      <div className="Home">
        <div className={style.heroOuter}>
          <div className={style.hero}>
            <div className="container">
              <div className={style.heroContent}>
                <h1>The Plenary Preventive Platform</h1>
                <BrowserView>
                  <h5>Get care from a professional<br /> First 15 minutes are FREE</h5>
                  <h5>Search within skills or consultants<br /> or choose a service</h5>
                </BrowserView>
                <MobileView>
                  <h5>First 15 minutes are FREE</h5>
                </MobileView>
                <div className="searchWrapper">
                  <form className={style.search} onSubmit={e => { e.preventDefault(); props.history.push(`/search?q=${search}`) }}>
                    <img src={searchIcon} alt="s" className={style.searchIcon} />
                    <input type="text" placeholder="Search..." onChange={e => setSearch(e.target.value)} />
                    <button className={style.searchButton} onClick={e => props.history.push(`/search?q=${search}`)}>
                      <img src={arrowIcon} alt="search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobileOnly ?
          <div className={`${style.servicesSm}`}>
            <div className={style.mobileServicesHeader}>
              <h2 className="m-0">Our Services</h2>
              <p className="m-0 mt-2">Ergonomy & Preventive</p>
            </div>
            <Slider {...sliderSettings} className="serviceSlider">
              <div className="slide">
                <Link to="therapists?spec=chiropractic" className={style.service}>
                  <img src={Chiropractic} alt="Chiropractic" />
                  <h3 className={style.serviceTitle}> Chiropractic <ArrowRightIcon size={13} color="white" /></h3>
                </Link>
              </div>
              <div className="slide">
                <Link to="therapists?spec=kinesiology" className={style.service}>
                  <img src={Kinesiology} alt="Kinesiology" />
                  <h3 className={style.serviceTitle}> Kinesiology <ArrowRightIcon size={13} color="white" /></h3>
                </Link>
              </div>
              <div className="slide">
                <Link to="therapists?spec=physiotherapy" className={style.service}>
                  <img src={Physiotherapy} alt="Physiotherapy" />
                  <h3 className={style.serviceTitle}> Physiotherapy <ArrowRightIcon size={13} color="white" /></h3>
                </Link>
              </div>
              <div className="slide">
                <Link to="/therapists?spec=gynecology" className={style.service}>
                  <img src={Gynecology} alt="Gynecology" />
                  <h3 className={style.serviceTitle}> Gynecology <ArrowRightIcon size={13} color="white" /></h3>
                </Link>
              </div>
              <div className="slide">
                <Link to="/therapists?spec=psychology" className={style.service}>
                  <img src={Psychology} alt="Psychology" />
                  <h3 className={style.serviceTitle}> Psychology <ArrowRightIcon size={13} color="white" /></h3>
                </Link>
              </div>
              <div className="slide">
                <Link to="/therapists?spec=nutrition" className={style.service}>
                  <img src={Nutrition} alt="Nutrition" />
                  <h3 className={style.serviceTitle}> Nutrition <ArrowRightIcon size={13} color="white" /></h3>
                </Link>
              </div>
            </Slider>
          </div>
          :

          <div className={`${style.services} pt-5 pb-5`}>
            <div className="container">
              <h2 className="pt-2 text-center letter-spacing-low">OUR ERGONOMY SERVICES</h2>
              <div className="row mt-5">
                <div className="col-xs-12 col-sm-4">
                  <Link to="/therapists?spec=chiropractic" className={style.service}>
                    <img src={Chiropractic} alt="Chiropractic" />
                    <h3 className={style.serviceTitle}> Chiropractic </h3>
                  </Link>
                </div>
                <div className="col-xs-12 col-sm-4">
                  <Link to="/therapists?spec=kinesiology" className={style.service}>
                    <img src={Kinesiology} alt="Kinesiology" />
                    <h3 className={style.serviceTitle}> Kinesiology </h3>
                  </Link>
                </div>
                <div className="col-xs-12 col-sm-4">
                  <Link to="/therapists?spec=physiotherapy" className={style.service}>
                    <img src={Physiotherapy} alt="Physiotherapy" />
                    <h3 className={style.serviceTitle}> Physiotherapy </h3>
                  </Link>
                </div>
              </div>
              <h2 className="mt-4 pt-4 text-center letter-spacing-low">OTHER SERVICES</h2>
              <div className="row mt-5">
                <div className="col-xs-12 col-sm-4">
                  <Link to="/therapists?spec=gynecology" className={style.service}>
                    <img src={Gynecology} alt="Gynecology" />
                    <h3 className={style.serviceTitle}> Gynecology </h3>
                  </Link>
                </div>
                <div className="col-xs-12 col-sm-4">
                  <Link to="/therapists?spec=psychology" className={style.service}>
                    <img src={Psychology} alt="Psychology" />
                    <h3 className={style.serviceTitle}> Psychology </h3>
                  </Link>
                </div>
                <div className="col-xs-12 col-sm-4">
                  <Link to="therapists?spec=nutrition" className={style.service}>
                    <img src={Nutrition} alt="Nutrition" />
                    <h3 className={style.serviceTitle}> Nutrition </h3>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }

        <Link to="therapists" className="td-none">
          <div className={`${style.consultant} mt-5 mb-5 pt-5 pb-5`}>
            <div className="container">
              <div className={`${style.consultantCover}`}>
                <h5>Consult with our</h5>
                <h2 className="letter-spacing-med">ERGONOMISTS</h2>
              </div>
            </div>
          </div>
        </Link>

        <div className={`${style.howItWorks} pt-5 pb-5 mb-5`}>
          <div className="container">
            <h2 className="text-center letter-spacing-low">HOW IT WORKS</h2>
            <div className="row mt-5">
              <div className="col-xs-12 col-sm-4">
                <div className={style.hiwItem}>
                  <img src={featureIcon1} alt="create" />
                  <h3 className={style.hiwTitle}>Select Subject or Search</h3>
                  <p>Select the area you need help and explore pool of consultants.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className={style.hiwItem}>
                  <img src={featureIcon2} alt="add content" />
                  <h3 className={style.hiwTitle}>Select your Consultant</h3>
                  <p>Select the best match for your problem.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className={style.hiwItem}>
                  <img src={featureIcon3} alt="launch" />
                  <h3 className={style.hiwTitle}>Meet or Schedule</h3>
                  <p>Find who can meet you promptly or fits your schedule.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${style.topTherapists} pt-5 pb-5`}>
          <div className="container">
            <BrowserView>
              <h2 className="text-center letter-spacing-low pb-3">TOP THERAPISTS</h2>
            </BrowserView>
            <MobileView>
              <div className={style.mobileTherapistsHeader}>
                <p className="m-0">Book a meeting with our</p>
                <h2 className="m-0 mt-2">TOP THERAPISTS</h2>
              </div>
            </MobileView>
            <div className={`row mt-5 ${style.topTherapistsRow}`}>
              {therapists.map(therapist => (
                <div className="col-sm-6 col-lg-3">
                  <Link to={`/therapist/${therapist.id}`} className={style.therapist}>
                    {therapist.photo
                      ? <img src={withCdnUrl(therapist.photo)} alt={getDisplayName(therapist)} />
                      : <img src={noAvatar} alt={getDisplayName(therapist)} />
                    }
                    <h3 className="m-0 mt-3 letter-spacing-low fw-normal">{getDisplayName(therapist)}</h3>
                    <div className="d-flex flex-space-between">
                      <h4 className={`${style.spec} m-0 fw-medium`}>{therapist.specialties[0]}</h4>
                      <div className="score d-flex flex-center">
                        <Rating score={therapist.rating} />
                        <div className="ml-2 fw-bold fs-sm">{therapist.reviewsCount || 0} reviews</div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
              {/* <div className="col-sm-6 col-lg-3">
                <Link to="/therapist/6112623e1fa2a22e0ce1ad11" className={style.therapist}>
                  <img src={therapist2} alt="t1" />
                  <h3 className="m-0 mt-3 letter-spacing-low fw-normal">Rachel Cruise</h3>
                  <div className="d-flex flex-space-between">
                    <h4 className={`${style.spec} m-0 fw-medium`}>Kinesiologic</h4>
                    <div className="score d-flex flex-center">
                      <Rating score="3" />
                      <div className="ml-2 fw-bold fs-sm">1 reviews</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-3">
                <Link to="/therapist/6112623e1fa2a22e0ce1ad11" className={style.therapist}>
                  <img src={therapist3} alt="t1" />
                  <h3 className="m-0 mt-3 letter-spacing-low fw-normal">Jenny Adams</h3>
                  <div className="d-flex flex-space-between">
                    <h4 className={`${style.spec} m-0 fw-medium`}>Nutritionist</h4>
                    <div className="score d-flex flex-center">
                      <Rating score="4" />
                      <div className="ml-2 fw-bold fs-sm">90 reviews</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-3">
                <Link to="/therapist/6112623e1fa2a22e0ce1ad11" className={style.therapist}>
                  <img src={therapist4} alt="t1" />
                  <h3 className="m-0 mt-3 letter-spacing-low fw-normal">Alex Resstler</h3>
                  <div className="d-flex flex-space-between">
                    <h4 className={`${style.spec} m-0 fw-medium`}>Psychologe</h4>
                    <div className="score d-flex flex-center">
                      <Rating score="4" />
                      <div className="ml-2 fw-bold fs-sm">6 reviews</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-3">
                <Link to="/therapist/6112623e1fa2a22e0ce1ad11" className={style.therapist}>
                  <img src={therapist5} alt="t1" />
                  <h3 className="m-0 mt-3 letter-spacing-low fw-normal">John Maser</h3>
                  <div className="d-flex flex-space-between">
                    <h4 className={`${style.spec} m-0 fw-medium`}>Physiotherapist</h4>
                    <div className="score d-flex flex-center">
                      <Rating score="5" />
                      <div className="ml-2 fw-bold fs-sm">3 reviews</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-3">
                <Link to="/therapist/6112623e1fa2a22e0ce1ad11" className={style.therapist}>
                  <img src={therapist6} alt="t1" />
                  <h3 className="m-0 mt-3 letter-spacing-low fw-normal">Jackson Pan</h3>
                  <div className="d-flex flex-space-between">
                    <h4 className={`${style.spec} m-0 fw-medium`}>Chiropractor</h4>
                    <div className="score d-flex flex-center">
                      <Rating score="3" />
                      <div className="ml-2 fw-bold fs-sm">41 reviews</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-3">
                <Link to="/therapist/6112623e1fa2a22e0ce1ad11" className={style.therapist}>
                  <img src={therapist7} alt="t1" />
                  <h3 className="m-0 mt-3 letter-spacing-low fw-normal">Alicia Andrew</h3>
                  <div className="d-flex flex-space-between">
                    <h4 className={`${style.spec} m-0 fw-medium`}>Nutritionist</h4>
                    <div className="score d-flex flex-center">
                      <Rating score="5" />
                      <div className="ml-2 fw-bold fs-sm">34 reviews</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-3">
                <Link to="/therapist/6112623e1fa2a22e0ce1ad11" className={style.therapist}>
                  <img src={therapist8} alt="t1" />
                  <h3 className="m-0 mt-3 letter-spacing-low fw-normal">Alex Resstler</h3>
                  <div className="d-flex flex-space-between">
                    <h4 className={`${style.spec} m-0 fw-medium`}>Psychologe</h4>
                    <div className="score d-flex flex-center">
                      <Rating score="5" />
                      <div className="ml-2 fw-bold fs-sm">89 reviews</div>
                    </div>
                  </div>
                </Link>
              </div>*/}
            </div>
            <div className="text-center mt-3 mb-4">
              <Button
                disableElevation
                style={{ background: 'white', color: '#333' }}
                variant="contained"
                onClick={() => props.history.push('/therapists')}>
                View More <img src={arrowRightIcon} alt=">" className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
        <div className={`${style.whyUs} pt-5 pb-5`}>
          <div className="container">
            <h2 className="text-center letter-spacing-low">WHY BANKWELL</h2>
            <p className={style.whyUsDescription}>Here are some reasons you should use BankWell as your primary ergonomy service.</p>
            <div className="row mt-5">
              <div className="col-xs-12 col-sm-4">
                <div className={style.whyUsItem}>
                  <img src={featureIcon1} alt="Robust Workflow" />
                  <h3 className={style.hiwTitle}>Feature Rich</h3>
                  <p>BankWell is a feature rich platform that fits all your needs.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className={style.whyUsItem}>
                  <img src={featureIcon2} alt="User Friendly" />
                  <h3 className={style.hiwTitle}>User Friendly</h3>
                  <p>BankWell designed based on a modern, minimal and polished style.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className={style.whyUsItem}>
                  <img src={featureIcon3} alt="Easy to Access" />
                  <h3 className={style.hiwTitle}>Easy to Access</h3>
                  <p>You can access to BankWell via your mobile or desktop anywhere.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className={style.whyUsItem}>
                  <img src={featureIcon4} alt="Proficiency" />
                  <h3 className={style.hiwTitle}>Proficiency</h3>
                  <p>There are plenty of professional therapists you can rich in BankWell.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className={style.whyUsItem}>
                  <img src={featureIcon5} alt="Secure" />
                  <h3 className={style.hiwTitle}>Secure</h3>
                  <p>BankWell securely connects you to your therapists.</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className={style.whyUsItem}>
                  <img src={featureIcon6} alt="Guaranty" />
                  <h3 className={style.hiwTitle}>Guaranty</h3>
                  <p>We guaranties the quality of the service. So you can join with the peace of mind.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Home);